<template>
  <div class="verify-user">
    <div class="container mt-3 mb-3">
      <div class="row">
        <div class="col-lg-3 col-md-4 col-sm-5 col-xs-12">
          <!-- Imagen del perfil del usuario -->
          <div class="user-info-container">
            <img class="profile-image mx-auto d-block" :src="user.picture" alt="Imagen de perfil">
            <!-- <img class="profile-image mx-auto d-block" src="https://picsum.photos/200/300" alt="Imagen de perfil"> -->
            <h4 class="mt-2">Nombre</h4>
            <p>{{user.name}} {{user.lastName}}</p>
            <h4>Edad</h4>
            <p>{{user.age}}</p>
            <h4>Teléfono</h4>
            <p>{{user.phone}}</p>
          </div>
          <button @click="verifyUser" type="button" class="btn btn-primary btn-block">Verificar identidad</button>
          <button @click="revokeUser" type="button" class="btn btn-outline-danger btn-block">Rechazar identidad</button>
        </div>
        <div class="col-lg-9 col-md-8 col-sm-7 col-xs-12">
          <div class="row">
            <div class="col">
              <h3>Documentos</h3>
            </div>
          </div>
          <div class="row">
            <div class="col" @click="openImage(user.credentialOne)">
              <img id="credential-two" class="credential-image d-block" :src="user.credentialOne" alt="credentialOne">
              <!-- <img id="credential-two" class="credential-image d-block" src="https://picsum.photos/1200/1800" alt="credentialOne"> -->
            </div>
            <div class="col" @click="openImage(user.credentialTwo)" v-if="user.age>17">
              <img id="credential-one" class="credential-image d-block" :src="user.credentialTwo" alt="credentialTwo">
              <!-- <img id="credential-one" class="credential-image d-block" src="https://picsum.photos/1200/1800" alt="credentialTwo"> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="full-screen-image" v-if="imageOppened.length > 0">
      <div class="controls">
        <button type="button" class="btn btn-danger float-right" @click="openImage('')">
          <span>&times;</span>
        </button>
      </div>
      <div class="image"><img class="mx-auto d-block" :src="imageOppened"></div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VerifyUser',
  data() {
    return {
      user: this.$route.params.user,
      imageOppened: ''
    }
  },
  methods: {
    openImage(image) {
      this.imageOppened = image
    },
    revokeUser() {
      const alert = confirm('¿Está seguro de que desea rechazar la identidad de este usuario?\nEsta acción no se puede deshacer.')
      if (alert) {
        this.axios.post('/api/users/revoke/'+this.user._id,
          {
            headers: {
              'Authorization': this.$getAuthorization(),
            },
          })
        .then(response => {
          if (response.data.status === 'success') {
            this.$router.go(-1);
          } else {
            console.log(response.data);
            this.$showToast(response.data.message, 'error');
          }
        })
        .catch(error => {
          console.log(error);
        });
      }
    },
    verifyUser() {
      const alert = confirm('¿Está seguro de que desea verificar la identidad de este usuario?\nEsta acción no se puede deshacer.')
      if (alert) {
        this.axios.post('/api/users/verify/'+this.user._id,
          {
            headers: {
              'Authorization': this.$getAuthorization(),
            },
          })
        .then(response => {
          if (response.data.status === 'success') {
            this.$router.go(-1);
          } else {
            console.log(response.data);
            this.$showToast(response.data.message, 'error');
          }
        })
        .catch(error => {
          console.log(error);
        });
      }
    },
  }
}
</script>

<style scoped>
.profile-image {
  height: 200px;
  width: 200px;
  border-radius: 15%;
  object-fit: cover;
}
.credential-image {
  width: 100%;
  height: auto;
  border-radius: 20px;
  z-index: 10;
}
.image-oppened {
  z-index: 100;
  position: fixed;
  top: 0;
  left: 0;
  width: auto;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.full-screen-image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}
.full-screen-image .image {
  width: 100%;
  height: 100%;
  overflow-x: auto;
  overflow-y: auto;
}
.controls .close {
  padding: 10px;
  cursor: pointer;
}
</style>